/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@import 'components/expanded-table';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600&display=swap');
@import 'assets/styles/splash-screen';
@import '@angular/cdk/overlay-prebuilt.css';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

:root {
  --fuse-primary-50: #e3f2fd;
  --fuse-primary-100: #bbdefb;
  --fuse-primary-500: #2196f3;
  --fuse-primary-700: #1976d2;
  --fuse-warn-100: #ffcdd2;
  --fuse-warn-700: #d32f2f;

  .theme-brand .light .mat-mdc-tab-group,
  .theme-brand .light .mat-mdc-tab-nav-bar,
  .theme-brand.light .mat-mdc-tab-group,
  .theme-brand.light .mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: transparent;
    --mat-tab-header-active-ripple-color: transparent;
    --mat-tab-header-inactive-ripple-color: transparent;
    --mat-tab-header-active-focus-indicator-color: transparent;
    --mat-tab-header-active-hover-indicator-color: transparent;
    --mat-tab-header-active-label-text-color: #fff;
  }

  .light-theme {
    --mat-table-header-headline-weight: 500;
  }

  .mat-mdc-floating-label {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }
}

.CodeMirror {
  resize: vertical;
}

.bg-oa-image {
  background-image: url('/assets/images/ui/OpenApp_background.jpg');
  background-position-x: 50%;
  background-size: cover;
}

.mat-tab-header-pagination-disabled {
  opacity: 0;
}

.bg-oa-gradient {
  background: rgb(98, 255, 255);
  background: linear-gradient(
    40deg,
    rgba(98, 255, 255, 1) 4%,
    rgba(0, 235, 255, 1) 10%,
    rgba(0, 213, 255, 1) 15%,
    rgba(0, 189, 255, 1) 21%,
    rgba(0, 163, 255, 1) 28%,
    rgba(101, 153, 255, 1) 35%,
    rgba(149, 141, 247, 1) 44%,
    rgba(187, 127, 232, 1) 52%,
    rgba(221, 141, 217, 1) 62%,
    rgba(240, 160, 208, 1) 72%,
    rgba(248, 183, 206, 1) 80%,
    rgba(249, 207, 213, 1) 95%
  );
}

table tbody tr:hover {
  background-color: rgba(222, 233, 69, 0.4) !important;
  cursor: pointer;
}

table thead th.mat-header-cell {
  text-align: left;
  font-size: 0.75rem;
}

table tbody td.mat-cell {
  font-size: 0.75rem;
}

.mat-mini-fab {
  box-shadow: none !important;
}

.prose pre {
  background: white;
  code {
    background-color: white !important;
    color: black;
  }
}

.mat-raised-button,
.mat-flat-button {
  color: #fff !important;
}

.mat-mdc-dialog-container {
  .mdc-dialog {
    &__title {
      padding: 0;
      font-size: 1rem !important;
      font-weight: 600 !important;
    }
  }
  mat-dialog-content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.mat-snack-bar-container {
  background: #323232 !important; // Tło powiadomienia
  color: #ffffff !important; // Kolor tekstu
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border-radius: 8px; // Zaokrąglenie rogów
  padding: 16px 24px;

  // Stylowanie przycisku akcji
  .mat-button-wrapper {
    color: #ff9800 !important; // Kolor przycisku "OK"
    font-weight: bold;
  }
}

$parentMenuColor: #2196f3;

.mat-mdc-tab-header {
  .mat-mdc-tab-label-container {
    .mat-mdc-tab-list {
      .mat-mdc-tab-labels {
        .mat-mdc-tab {
          padding: 0 10px !important;
          border-radius: 0 !important;
          color: $parentMenuColor;
          border: 1px solid $parentMenuColor !important;
          flex-grow: 1;
          height: 45px !important;
          margin-right: 2px;

          .mdc-tab__text-label {
            color: $parentMenuColor;
          }

          &.mdc-tab--active {
            background-color: $parentMenuColor !important;
            .mdc-tab__text-label {
              color: #fff !important;
            }
          }
        }
      }
    }
  }
}
