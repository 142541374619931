.expanded-table {
  width: 100%;
  overflow-x: auto;
  display: block;
  table {
    width: 100% !important;
    table-layout: auto;
  }

  tr.example-detail-row {
    height: 0;
  }

  tr.example-expanded-row {
    background: #f6f6f6;
  }

  tr.today-row:not(.example-expanded-row) {
    background: #e8f5e9;
  }

  tr.example-element-row:not(.example-expanded-row):hover {
    background: whitesmoke;
    cursor: pointer;
  }

  tr.example-element-row:not(.example-expanded-row):active {
    // background: #efefef;
  }

  .example-element-row td {
    border-bottom-width: 0;
  }

  .mat-column-expandedDetail {
    background-color: #f6f6f6;
  }

  .example-element-detail {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .flex {
      padding: 30px 0 30px 0;

      div {
        padding-right: 45px;
        flex-grow: 1;

        &.products {
          thead {
            td {
              font-weight: 600;
            }
          }
          .scrollable-area {
            max-height: 200px;
          }
          table {
            width: auto;
            td {
              padding: 2px 40px 2px 0;
            }
          }
        }
      }

      h3 {
        font-weight: bold;

        padding-bottom: 5px;
      }
    }
  }

  .example-element-diagram {
    //   min-width: 80px;
    //   border: 2px solid black;
    padding: 8px;

    margin: 8px 0;
    //   height: 104px;
  }

  .example-element-symbol {
    font-weight: bold;
    font-size: 40px;
    line-height: normal;
  }

  .example-element-description {
    padding: 16px;
  }

  .example-element-description-attribution {
    opacity: 0.5;
  }
}
