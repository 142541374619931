body fuse-splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(98, 255, 255);
  background: linear-gradient(
    344deg,
    rgba(98, 255, 255, 1) 4%,
    rgba(0, 235, 255, 1) 10%,
    rgba(0, 213, 255, 1) 18%,
    rgba(0, 189, 255, 1) 27%,
    rgba(0, 163, 255, 1) 38%,
    rgba(101, 153, 255, 1) 47%,
    rgba(149, 141, 247, 1) 56%,
    rgba(187, 127, 232, 1) 68%,
    rgba(221, 141, 217, 1) 75%,
    rgba(240, 160, 208, 1) 82%,
    rgba(248, 183, 206, 1) 89%,
    rgba(249, 207, 213, 1) 95%
  );

  color: #f9fafb;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

body fuse-splash-screen img {
  width: 250px;
  max-width: 250px;
}

body fuse-splash-screen .spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  width: 56px;
}

body fuse-splash-screen .spinner > div {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
  animation: fuse-bouncedelay 1s infinite ease-in-out both;
}

body fuse-splash-screen .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

body fuse-splash-screen .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes fuse-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes fuse-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

body:not(.fuse-splash-screen-hidden) {
  overflow: hidden;
}

body.fuse-splash-screen-hidden fuse-splash-screen {
  visibility: hidden;
  opacity: 0;
}
